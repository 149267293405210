import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const paymentChannelList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'shopbank/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get payment chennel list err : ', err)

      return {}
    })

  return response
}

const paymentAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'shopbank',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add payment chennel err : ', err)

      return {}
    })

  return response
}

const paymentUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'shopbank',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add payment chennel err : ', err)

      return {}
    })

  return response
}

const paymentUpdateStatus = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'shopbank/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update status payment err : ', err)

      return {}
    })

  return response
}
export default {
  paymentChannelList, paymentAdd, paymentUpdate, paymentUpdateStatus,
}
